import { instance } from './request'

const apiPrefix = '/api/c/dell/tjl/'

/**
 * 保存用户信息
 * @param {string} openid
 * @param {string} nickname
 * @param {string} avatar_url
 */
export function saveUser (openid,nickname = undefined,avatar_url = undefined) {
  return instance.post(apiPrefix + 'saveUser',{openid,nickname,avatar_url})
}
export function loginByCode (code) {
  return instance.post(apiPrefix + 'loginByCode',{code})
}

export function getUserByOpenid (openid) {
  return instance.post(apiPrefix + 'getUserByOpenid',{openid})
}

/**
 * 使用用户id获取游戏详情
 * @param {*} user_id
 * @returns {Promise<{poster_lives:number,posters:{poster_type:number,url:number}[]}>}
 */
export function getDetailById (user_id) {
  return instance.post(apiPrefix + 'getDetailById',{user_id})
}

/**
 * 生成海报
 * @param {*} user_id
 * @param {*} name
 * @param {*} location
 * @param {*} year
 * @param {*} words
 * @param {*} template_id
 * @param {*} is_custom
 * @param {*} need_generate
 *
 */
export function generatePoster ({user_id,name,location,year,words,template_id,is_custom,need_generate}) {
  return instance.post(apiPrefix + 'generatePoster',{user_id,name,location,year,words,template_id,is_custom,need_generate})
}

/**
 * 使用海报id获取海报详情
 * @returns {Promise<{poster_type:number,url:number}>}
 */
export function getPosterById (poster_id) {
  return instance.post(apiPrefix + 'getPosterById',{poster_id})
}

/**
 * checkFansById
 * @param {*} user_id
 */
export function checkFansById (user_id) {
  return instance.post(apiPrefix + 'checkFansById',{user_id})
}

export function checkMemberById (user_id) {
  return instance.post(apiPrefix + 'checkMemberById',{user_id})
}

/**
 * 使用双方用户id查询助力信息
 * @param {*} user_id
 * @param {*} helper_id
 */
export function getHelpDetail (user_id,helper_id) {
  return instance.post(apiPrefix + 'getHelpDetail',{user_id,helper_id})
}

/**
 * helping
 * @param {*} user_id
 * @param {*} helper_id
 */
export function helping (user_id,helper_id) {
  return instance.post(apiPrefix + 'helping',{user_id,helper_id})
}

/**
 * sendSms
 * @param {*} mobile
 */
export function sendSms (mobile) {
  return instance.post(apiPrefix + 'sendSms',{mobile})
}

/**
 * registerMember
 * @param {{name:string, mobile:string, code:string, job:string}}
 */
export function registerMember ({name,mobile,code,job,user_id,address}) {
  return instance.post(apiPrefix + 'registerMember',{name,mobile,code,job,user_id,address})
}

/**
 * getLotteryResult
 * @param {number} user_id
 * @returns {Promise<{has_received,has_used}[]>}
 */
export function getLotteryResult (user_id) {
  return instance.post(apiPrefix + 'getLotteryResult',{user_id})
}

/**
 * gambling抽奖
 * @param {number} user_id
 * @returns {Promise<{prize_id:number}>}
 */
export function gambling (user_id) {
  return instance.post(apiPrefix + 'gambling',{user_id})
}

export function getJsConfig (url) {
  return instance.post(apiPrefix + 'getJsConfig',{url})
}

export function uploadToMeitu (user_id, media_id, base64Data) {
  return instance.post(apiPrefix + 'uploadToMeitu2',{user_id, media_id, base64Data})
}

/**
 *  提交领奖信息
 * @param {*} param0
 * @param {string} param0.name
 * @param {string} param0.phone
 * @param {string} param0.address
 */
export function submitPrizeReceiveInfo ({name,mobile,address,user_id}) {
  return instance.post(apiPrefix + 'submitPrizeReceiver',{name,mobile,address,user_id})
}
