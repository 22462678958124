import React, {PureComponent} from 'react'
import Page from '../../components/Page'
import { getUserInfo, getResultUrl, saveResultUrl, removeResultUrl,getPostParams } from '../../utils/store'

import { WORD_TEMPLATE } from '../../utils/helper'
import Toast from '../../components/antd-mobile/Toast'
import WeuiLoading from '../../components/Loading'
import { uploadToMeitu } from '../../utils/api'
//显示我的所有的海报，按照分类来分组

import MtaH5 from "mta-h5-analysis"
import './PageUploadWx.css'

export default class PageUploadWx extends Page {
  constructor (props) {
    super(props)
    this.userId = undefined
    this.state = {
      loading: false,
      preview: undefined,
      url: undefined
    }
    this.handleUpload = this.handleUpload.bind(this)
  }

  componentDidMount () {
    if (!this.hasLogin()) {
      return this.redirectToLogin(undefined,false)
    }

    MtaH5.pgv()

    const userInfo = getUserInfo()
    this.userId = userInfo.id
    removeResultUrl();
    this.initShare();
  }

  handleGeneratePoster () {
    const resourceUrl = getResultUrl()
    const {url} = this.state

    if (url || resourceUrl) {
      MtaH5.clickStat("jump_to_result", {});
      this.historyReplace('/result')
    } else {
      Toast.fail('请先上传头像哦', 1)
    }
  }

  handleFileChange(index, e) {
    let {files} = this.state;
    const userInfo = getUserInfo()
    let inputFiles = e.target.files;
    if (inputFiles && inputFiles.length) {
      // removeResultUrl();
      this.setState({loading: true})
      let file = inputFiles[0];
      files[index].file = file;
      if (file.size > 5 * 1024 * 1024) {
        return Toast.fail("文件太大，请不要超过5M", 1)
      }
      var fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = (res) => {
        let {files} = this.state;
        let base64 = fr.result;
        files[index].preview = base64;
        //上传图片
        uploadToMeitu(userInfo.id, undefined, base64).then((result) => {
          saveResultUrl(result.url)
          this.setState({loading: false, url: result.url});
        }).catch((error) => {
          Toast.fail(error.toString().replace('APIError:', ''), 2)
          removeResultUrl();
          this.setState({loading: false})
        });
        this.setState({files})
      }
      fr.onerror = (error) => {
        Toast.fail("读取文件出错:" + error.toString(), 1)
      }
    }
  }

  handleUpload () {
    const userInfo = getUserInfo()
    window.wx.chooseImage({
      count: 1, // 默认9
      sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
      success: (res) => {
        var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        if (localIds.length) {
          // if (window.__wxjs_is_wkwebview) {
          //   window.wx.getLocalImgData({
          //     localId: localIds[0], // 图片的localID
          //     success: (localData) => {
          //       this.setState({
          //         preview: localData
          //       })
          //     },
          //     fail: (e) => {
          //       this.setState({
          //         preview: localIds[0]
          //       })
          //     }
          //   });
          // } else {
          //   this.setState({
          //     preview: localIds[0]
          //   })
          // }

          this.setState({
            preview: localIds[0],
          })

          window.wx.uploadImage({
            localId: localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: (res) => {
              this.setState({loading: true})
              var serverId = res.serverId; // 返回图片的服务器端ID
              uploadToMeitu(userInfo.id, serverId, undefined).then((result) => {
                saveResultUrl(result.url)
                this.setState({loading: false, url: result.url});
              }).catch((error) => {
                Toast.fail(error.toString().replace('APIError:', ''), 2)
                removeResultUrl();
                this.setState({loading: false})
              });
            }
          })
        } else {
          this.setState({loading: false})
        }
      }
    })
  }

  render () {
    const {
      preview,
      url,
      loading
    } = this.state

    let commitBtnClassName = 'commit-btn'

    if (url) {
      commitBtnClassName = 'commit-btn commit-btn-animation'
    }

    return <div className="page-my bg1">
      <div className='bg-post'>
        <img src="https://static.picbling.cn/3051d55a-6fbe-40ed-831f-4153fbb9a426.png" style={{width: '100%'}} alt=""/>
      </div>
      <div className='bg-title'>
        <img src="https://static.picbling.cn/cda80ce1-e213-4bfb-bb43-e76c093e6fb8.png" style={{width: '100%'}} alt=""/>
      </div>
      <div className="row1">
        <div className="file_container" onClick={this.handleUpload.bind(this)}>
          {/*<input onChange={this.handleFileChange.bind(this, 0)} accept="image/*" type="file"/>*/}
          <ImagePreview preview={preview}/>
        </div>
      </div>
      <div className={commitBtnClassName}
              onClick={this.handleGeneratePoster.bind(this)}>
        <img style={(this.isIphone6() || this.isIphone6P()) ? {width: '70%'} : {width: '100%'}} src="https://static.picbling.cn/b83fc6cd-8c7c-4fb3-8402-c8065ce226d9.png" alt=""/>
      </div>
      <WeuiLoading loading={loading} content={'正在上传'}/>
    </div>
  }
}

class ImagePreview extends PureComponent {
  render() {
    const {preview} = this.props;
    return preview ? <div>
      <img className="img-preview" src={preview} alt=""/>
      <img className='img-mask' src="https://static.picbling.cn/9f68307f-301d-4031-a592-a8b875f272a7.png" alt=""/>
    </div> : <span/>
  }
}
