import React,{ Component } from 'react'
import { Route,Switch,Redirect,withRouter } from 'react-router-dom'

import Index from './pages/Index/PageIndex'
import Upload from './pages/Upload/PageUpload'
import UploadWx from './pages/Upload2/PageUploadWx'
import Result from './pages/Result/PageResult'


import { verifyLoginInfoFromUrl } from './utils/oauth'
import { loginByCode,getUserByOpenid } from './utils/api'
import { saveUserInfo } from './utils/store'

import 'swiper/css/swiper.min.css'
import './styles/index.less'
import { loadImages } from './utils/preload'
import { isWxBrowser } from './utils/helper'

//TODO Web Template Studio: Add routes for your new pages here.
class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      processOAuthLogin: true,
      ooo:false,
    }
  }

  componentDidMount () {
    if(!isWxBrowser()){
      return this.setState({ooo:true});
    }
    const params = verifyLoginInfoFromUrl(window.location.search)
    if (params && params.code) {
      loginByCode(params.code).then((userInfo) => {
        saveUserInfo(userInfo)
        this.setState({processOAuthLogin: false})

      })
    } else {
      this.setState({processOAuthLogin: false})
    }
    loadImages()
  }

  render () {
    const {processOAuthLogin,ooo} = this.state
    if (processOAuthLogin) {
      return <div className="loading"/>
    }
    if(ooo){
      return <div/>
    }
    return (
      <React.Fragment>
        <Switch>
          <Redirect exact path="/" to="/index"/>
          <Route path="/index" component={Index}/>
          <Route path="/upload" component={Upload}/>
          <Route path="/uploadwx" component={UploadWx}/>
          <Route path="/result" component={Result}/>
          <Route path="*" component={Index}/>
        </Switch>
      </React.Fragment>
    )
  }
}

export default withRouter(App)
