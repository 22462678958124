import client from 'axios'
import { apiDomain as baseURL, appDomain } from '../const'
import { getXAuthToken, removeUserInfo, removeXAuthToken } from '../utils/store'
// import { getWxOAuthUrl } from '../lib/wechat'

let isRedirecting401 = false
let pageContext = undefined

export class APIError extends Error{
    constructor(props){
        super(props);
        this.name = "APIError"
    }
}
export class HTTPError extends Error{
    constructor(props){
        super(props);
        this.name = "HTTPError"
    }
}


export const instance = client.create({
    baseURL,
    timeout: 30000,
    responseType: 'json',
    headers: { 'x-auth-token': 'anonymous' },
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 401
    },
    transformResponse: [function (data) {
        if (data && data.status === "failure") {
            data.errmsg = data.errMsg || data.errmsg
            if (data.errmsg && data.errmsg.indexOf("身份认证已失效") > -1) {
                if (isRedirecting401) {
                    return
                }

                if (!isRedirecting401) {
                    isRedirecting401 = true
                }
                removeUserInfo()
                removeXAuthToken()
                if (pageContext) {
                    //   let params = pageContext.getParams()
                    //   if (params.appid) {
                    //     let redirectUrl = getWxOAuthUrl(params.appid,appDomain + window.location.pathname)
                    //     window.location.replace(redirectUrl)
                    //   }
                }
                /*
                * 跳转到登录授权的页面
                * */
            }
        }
        return data
    }]

})

/**
 *
 * @param {{status:string,datas:any,?errmsg:string}} response
 * @returns {Promise<any>|*}
 */
const parsePbResponse = (response) => {
    let { data } = response
    if (data && data.status === "success") {
        return data.datas
    } else if (data) {
        return Promise.reject(new APIError(data.errmsg||data.datas))
    }
    // Do something with response data
    return response
}

instance.interceptors.request.use((config) => {
    config.headers["x-auth-token"] = getXAuthToken()
    return config
}, err => {
    console.log("instance.interceptors.request.use",err)
    return Promise.reject(err);
})

instance.interceptors.response.use(parsePbResponse, (err)=>{
    console.log("err",err);
    return Promise.reject(new HTTPError(err))
})

instance.setPageContext = function (thePageContext) {
    pageContext = thePageContext
}
