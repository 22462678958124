import store from '../lib/store';
const KEY_USERINFO = "uwer"
const KEY_TOKEN = "xauskdad2"
const KEY_RESULT_URL = "KEY_RESULT_URL"
const KEY_POSTER_PARAMS = "KEY_POSTER_PARAMS"
const KEY_ADTAG_PARAMS = "KEY_ADTAG_PARAMS"
const KEY_INDEX_HELP_SHOW = 'KEY_INDEX_HELP_SHOW'

export function saveADTAG(tag) {
    store.set(KEY_ADTAG_PARAMS, tag);
}
export function getADTAG() {
    return store.get(KEY_ADTAG_PARAMS);
}
export function saveUserInfo(userInfo) {
    store.set(KEY_USERINFO, userInfo);
}
export function getUserInfo() {
    return store.get(KEY_USERINFO);
}

export function removeUserInfo() {
    store.remove(KEY_USERINFO);
}

export function saveResultUrl(url) {
    store.set(KEY_RESULT_URL, url);
}
export function getResultUrl() {
    return store.get(KEY_RESULT_URL);
}
export function removeResultUrl() {
    store.remove(KEY_RESULT_URL);
}
export function saveXAuthToken(token, expiredAt) {
    store.set(KEY_TOKEN, token, new Date(expiredAt))
}
export function getXAuthToken() {
    return store.get(KEY_TOKEN)
}
export function removeXAuthToken() {
    store.remove(KEY_TOKEN)
}

export function savePostParams(params) {
    store.set(KEY_POSTER_PARAMS, params)
}

export function getPostParams() {
    return store.get(KEY_POSTER_PARAMS);
}

export function hasConfirmHelp() {
    return store.get(KEY_INDEX_HELP_SHOW)
}

export function markHelpModalConfirm() {
    store.set(KEY_INDEX_HELP_SHOW, 1)
}
