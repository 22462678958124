import React from 'react'
import qs from 'querystring'
import * as apiClient from '../utils/api'
import { instance as apiInstance } from '../utils/request'
import { genOAuthURL } from '../utils/oauth'
import { appDomain } from '../const'
import { getUserInfo } from '../utils/store'
import { getWxInstance } from '../lib/wechat'
import { getADTAG } from '../utils/store'

class Page extends React.Component {
  constructor (props) {
    super(props)
    this.apiClient = apiClient
    this.apiInstance = apiInstance.setPageContext(this)

  }

  initShare () {
    const title = '“别人家的孩子”A级通缉令发布'
    const desc = '“作案工具”究竟为何物，使用的人纷纷变学霸？'
    let link = "https://fwh.zetatech.cn/dell/tjl/index.html"

    if (getADTAG()) {
      link = link + '?ADTAG=' + getADTAG()
    }

    const imgUrl = 'https://static.picbling.cn/e3c53f45-ee8d-4d3d-908c-0a4b10d1be0f.png'
    getWxInstance().then((wx) => {
      wx.ready(()=>{
        try{wx.onMenuShareAppMessage({title,link,desc,imgUrl})}catch (e) {}
        try{wx.onMenuShareTimeline({title,link,imgUrl})}catch (e) {}
        try{wx.updateAppMessageShareData({title,desc,link,imgUrl})}catch(e){}
        try{wx.updateTimelineShareData({title,link,imgUrl})}catch (e) {}
      })
    })
    .catch(e=>{
      console.log(e)
    })
  }

  redirectToLogin (successUrl,userInfoScope = false) {
    successUrl = successUrl || appDomain + window.location.pathname
    window.location.replace(genOAuthURL(successUrl,userInfoScope))
  }

  hasLogin () {
    const userInfo = getUserInfo()
    return userInfo && userInfo.id && userInfo.openid
  }

  hasNicknameAndAvatar () {
    const userInfo = getUserInfo()
    return this.hasLogin() && userInfo.nickname && userInfo.avatar_url
  }

  getLocationQuery () {
    let {location} = this.props
    location = location || window.location
    return qs.parse(location.search.replace('?',''))
  }

  /**
   *
   * @returns {{appid:string,?successUrl:string}&{}}
   */
  getParams () {
    return this.props.match.params
  }

  historyPush (path) {
    this.props.history.push(path)
  }

  locationPush (path) {
    /*
    * basename="/nestle/dachu/
    * */
    window.location.href = '/nestle/dachu' + path
  }

  locationReplace (path) {
    window.location.replace('/nestle/dachu' + path)
  }

  historyReplace (path) {
    this.props.history.replace(path)
  }

  isIphone6() {
    console.log(window.screen)
    return /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 375 && (window.screen.height === 603 || window.screen.height === 579 || window.screen.height === 667);
  }

  isIphone6P() {
    return /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && (window.screen.height === 672 || window.screen.height === 648 || window.screen.height === 736);
  }

  isIphoneX() {
    return /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 375 && (window.screen.height === 724 || window.screen.height === 812);
  }

  isIphoneXR() {
    return /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 414 && (window.screen.height === 808 || window.screen.height === 896);
  }

  isIphoneXSMAX() {
    return /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && (window.screen.height === 808 || window.screen.height === 896);
  }

  render () {
    return <div/>
  }
}

export default Page
