const isDevelopment = process.env.NODE_ENV === 'development'
export const appDomain = 'https://fwh.zetatech.cn'
export const apiDomain = 'https://biruapi.picbling.cn'
// export const apiDomain = 'http://localhost:7777'
export const oAuthConfig = {
  appid: 'wxa29d243cecac1c3a',
  response_type: 'code',
  stat: ''
}

//wx5c2baf315e6f75ef



