import React, { Component } from 'react';
import './Loading.less'

export default class WeuiLoading extends Component{
  render(){
    return <div id="loadingToast" className="weui_loading_toast" style={{display:this.props.loading?'':'none'}}>
      <div className="weui_mask_transparent"/>
      <div className="weui_toast">
        <div className="weui_loading">
          <div className="weui_loading_leaf weui_loading_leaf_0"/>
          <div className="weui_loading_leaf weui_loading_leaf_1"/>
          <div className="weui_loading_leaf weui_loading_leaf_2"/>
          <div className="weui_loading_leaf weui_loading_leaf_3"/>
          <div className="weui_loading_leaf weui_loading_leaf_4"/>
          <div className="weui_loading_leaf weui_loading_leaf_5"/>
          <div className="weui_loading_leaf weui_loading_leaf_6"/>
          <div className="weui_loading_leaf weui_loading_leaf_7"/>
          <div className="weui_loading_leaf weui_loading_leaf_8"/>
          <div className="weui_loading_leaf weui_loading_leaf_9"/>
          <div className="weui_loading_leaf weui_loading_leaf_10"/>
          <div className="weui_loading_leaf weui_loading_leaf_11"/>
        </div>
        <p className="weui_toast_content">{this.props.content?this.props.content:'加载中'}</p>
      </div>
    </div>
  }
}
;

export  function loading() {
  return <div className="spinner" style={{left: (window.innerWidth - 40) / 2}}>
    <div style={{backgroundColor: 'rgb(244,113,117)'}} className="dot1"/>
    <div style={{backgroundColor: 'rgb(244,113,117)'}} className="dot2"/>
  </div>
}
