import React, {PureComponent} from 'react'
import Page from '../../components/Page'
import { getUserInfo, getResultUrl, saveResultUrl, removeResultUrl,getPostParams } from '../../utils/store'

import { WORD_TEMPLATE } from '../../utils/helper'
import Toast from '../../components/antd-mobile/Toast'
import WeuiLoading from '../../components/Loading'
import { uploadToMeitu } from '../../utils/api'
//显示我的所有的海报，按照分类来分组

import './PageUpload.css'

export default class PageUpload extends Page {
  constructor (props) {
    super(props)
    this.userId = undefined
    this.state = {
      loading: false,
      files: [{index: 0}],
    }
  }

  componentDidMount () {
    if (!this.hasLogin()) {
      return this.redirectToLogin(undefined,false)
    }

    const userInfo = getUserInfo()
    this.userId = userInfo.id
    removeResultUrl();
    this.initShare();
  }

  handleGeneratePoster () {
    const url = getResultUrl()

    if (!url) {
      Toast.fail('请先上传头像哦', 1)
    } else {
      this.historyReplace('/result')
    }
  }

  updateState (key,value) {
    this.setState({[key]: value})
  }

  handleFileChange(index, e) {
    let {files} = this.state;
    const userInfo = getUserInfo()
    let inputFiles = e.target.files;
    if (inputFiles && inputFiles.length) {
      // removeResultUrl();
      this.setState({loading: true})
      let file = inputFiles[0];
      files[index].file = file;
      if (file.size > 5 * 1024 * 1024) {
        return Toast.fail("文件太大，请不要超过5M", 1)
      }
      var fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = (res) => {
        let {files} = this.state;
        let base64 = fr.result;
        files[index].preview = base64;
        //上传图片
        uploadToMeitu(userInfo.id, undefined, base64).then((result) => {
          saveResultUrl(result.url)
          this.setState({loading: false, url: result.url});
        }).catch((error) => {
          Toast.fail(error.toString().replace('APIError:', ''), 2)
          removeResultUrl();
          this.setState({loading: false})
        });
        this.setState({files})
      }
      fr.onerror = (error) => {
        Toast.fail("读取文件出错:" + error.toString(), 1)
      }
    }
  }

  render () {
    const {
      files,
      url,
      loading
    } = this.state

    let commitBtnClassName = 'commit-btn'

    if (url) {
      commitBtnClassName = 'commit-btn commit-btn-animation'
    }

    return <div className="page-my bg1">
      <div className='bg-post'>
        <img src="https://static.picbling.cn/3051d55a-6fbe-40ed-831f-4153fbb9a426.png" style={{width: '100%'}} alt=""/>
      </div>
      <div className='bg-title'>
        <img src="https://static.picbling.cn/cda80ce1-e213-4bfb-bb43-e76c093e6fb8.png" style={{width: '100%'}} alt=""/>
      </div>
      <div className="row1">
        <div className="file_container">
          <input onChange={this.handleFileChange.bind(this, 0)} accept="image/*" type="file"/>
          <ImagePreview preview={files[0].preview} handleFileChange={this.handleFileChange.bind(this)}/>
        </div>
      </div>
      <div className={commitBtnClassName}
              onClick={this.handleGeneratePoster.bind(this)}>
        <img style={(this.isIphone6() || this.isIphone6P()) ? {width: '70%'} : {width: '100%'}} src="https://static.picbling.cn/b83fc6cd-8c7c-4fb3-8402-c8065ce226d9.png" alt=""/>
      </div>
      <WeuiLoading loading={loading} content={'正在上传'}/>
    </div>
  }
}

class ImagePreview extends PureComponent {
  render() {
    const {preview} = this.props;
    return preview ? <div>
      <img className="img-preview" src={preview} alt=""/>
      <img className='img-mask' src="https://static.picbling.cn/9f68307f-301d-4031-a592-a8b875f272a7.png" alt=""/>
    </div> : <span/>
  }
}
