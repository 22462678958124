import qs from 'querystring';
import { oAuthConfig } from '../const';

export function genOAuthURL(backurl, userInfo = false) {
    const baseUrl = "https://open.weixin.qq.com/connect/oauth2/authorize";

    let scope = userInfo ? 'snsapi_userinfo' : 'snsapi_base'

    const params = qs.stringify({ ...oAuthConfig, scope, redirect_uri: backurl });
    return baseUrl + "?" + params;
}

/**
 *
 * @param {*} params
 */
export function verifyLoginInfoFromUrl(search) {
    search = search.replace("?", "");
    const params = qs.parse(search);
    // const { source, appid, secret } = params;
    // if (appid !== oAuthConfig.appid || secret !== oAuthConfig.secret || source !== oAuthConfig.source) {
    //     return null;
    // }
    return params
}
