export function groupBy (arr = [],keyOrFn) {
  let res = {}
  let getKey = typeof keyOrFn === 'function' ? keyOrFn : (obj) => (obj[keyOrFn])
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i]
    let key = getKey(item)
    if (!res[key]) {
      res[key] = []
    }
    res[key].push(item)
  }
  return res
}

export const WORD_TEMPLATE = [
  "厨师的苦和累，只有自己知道，高温、油烟、作息不规律，时间长了一身职业病。",
  "每天再苦再累，只要别人喜欢吃我做的菜就足够了！",
  "厨房里没有啥技术活，只要把你看见的活都干了，手艺就长了。",
  "厨师也得学习，市场变化快，不学习早晚被淘汰。",
  "刚做厨师不久，每天都很辛苦，不知道这行有没有未来…"
]

export const POST_TYPES = {
  1: {
    name: '苦',
    card: 'https://biruimg.picbling.cn/nestle/dachu/card_v2_1.min.png',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_1.png',
    icon_dark: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_1_dark.min.png'
  },
  2: {
    name: '辣',
    card: 'https://biruimg.picbling.cn/nestle/dachu/card_v2_2.min.png',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_2.png',
    icon_dark: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_2_dark.png'
  },
  3: {
    name: '酸',
    card: 'https://biruimg.picbling.cn/nestle/dachu/card_v2_3.min.png',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_3.png',
    icon_dark: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_3_dark.png'
  },
  4: {
    name: '咸',
    card: 'https://biruimg.picbling.cn/nestle/dachu/card_v2_4.min.png',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_4.png?t=1',
    icon_dark: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_4_dark.png'
  },
  5: {
    name: '甜',
    card: 'https://biruimg.picbling.cn/nestle/dachu/card_v2_5.min.png',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_5.png',
    icon_dark: 'https://biruimg.picbling.cn/nestle/dachu/card_icon_5_dark.png'
  }
}


export const POST_TYPE_LIST = Object.keys(POST_TYPES).map(key => ({key: key * 1,...POST_TYPES[key]}))
export const POST_CARD_IMAGE_URLS = Object.keys(POST_TYPES).map(key => {
  const item=POST_TYPES[key];
  return [item.card,item.icon,item.icon_dark]
}).reduce((a,b) => {
  return a.concat(b)
})

export const PRIZE_TYPES = {
  PRIZE_1: 1,
  PRIZE_2: 2,
  PRIZE_3: 3,
  PRIZE_NULL: 4
}
export const PRIZE_ITEM = {
  [PRIZE_TYPES.PRIZE_NULL]: {
    name: '谢谢参与',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/icon_prize_0.png',
    image: '',
    description: ''
  },
  [PRIZE_TYPES.PRIZE_1]: {
    name: '一等奖',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/icon_prize_1.png'
    
  },
  [PRIZE_TYPES.PRIZE_2]: {
    name: '二等奖',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/icon_prize_2.png'
    
  },
  [PRIZE_TYPES.PRIZE_3]: {
    name: '三等奖',
    icon: 'https://biruimg.picbling.cn/nestle/dachu/icon_prize_3.png'
    
  }
}

export const PRIZE_ITEM_URLS= Object.keys(PRIZE_ITEM).map(key=>PRIZE_ITEM[key].icon);


export function insetBorder (style,{color,background}) {
  return {
    ...style,
    fontWeight:'bold',
    boxShadow: '0 0 0 2px ' + background + ' inset, 0 0 0 3px ' + color + ' inset'
  }
}

export function isWxBrowser() {
  let ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua)
}
