import { getJsConfig } from '../utils/api';

/**
 * @returns {Promise<Wecha>}
 */
export function getWxInstance() {
    return getJsConfig(window.location.href).then((jsConfig) => {
        if(window.wx){
            window.wx.config(jsConfig)
            return new Promise((resolve) => {
                resolve(window.wx);
            })
        }else {
            return Promise.reject("没有微信")
        }
    })
}
