import React from 'react'
import Page from '../../components/Page'
import './PageIndex.less'
import {getUserInfo} from '../../utils/store'
import MtaH5 from "mta-h5-analysis"
import { saveADTAG } from '../../utils/store'

export default class Index extends Page {
  constructor(props) {
    super(props)
    this.state = {
      hasPlayed: false,
      hasFinished: false,
    }
    this.handleEnter = this.handleEnter.bind(this)
    this.handleEnterWx = this.handleEnterWx.bind(this)
  }

  componentDidMount() {
    const query = this.getLocationQuery()
    if (query && query['ADTAG']) {
      saveADTAG(query['ADTAG'])
    }

    if (!this.hasLogin()) {
      return this.redirectToLogin(undefined, false)
    }
    this.initShare()

    this.initMtaH5()

    MtaH5.pgv()

    let videoPlayer = document.getElementById('videoPlayer')
    videoPlayer.addEventListener("ended", () => {
      this.setState({
        hasFinished: true
      })
    })
  }

  initMtaH5 () {
    // 初始化
    MtaH5.init({
      "sid":'500717807', //必填，统计用的appid
      "cid":'500717808', //如果开启自定义事件，此项目为必填，否则不填
      "autoReport":0,//是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
      "senseHash":0, //hash锚点是否进入url统计
      "senseQuery":0, //url参数是否进入url统计
      "performanceMonitor":0,//是否开启性能监控
      "ignoreParams":[] //开启url参数上报时，可忽略部分参数拼接上报
    });
  }


  componentWillUnmount() {
  }

  handleEnter() {
    // if (hasConfirmHelp()) {
    this.historyReplace('/upload')
    // } else {
    //   this.setState({isHelpModalShow: true})
    // }
  }

  handleEnterWx() {
    MtaH5.clickStat("jump_to_upload", {});
    // if (hasConfirmHelp()) {
    this.historyReplace('/uploadwx')
    // } else {
    //   this.setState({isHelpModalShow: true})
    // }
  }

  handleVideoPlay() {
    MtaH5.clickStat("play_video", {});
    
    let videoPlayer = document.getElementById('videoPlayer')
    videoPlayer.play()
    this.setState({
      hasPlayed: true
    })
  }

  render() {
    const {hasFinished, hasPlayed} = this.state

    let startBtnClassName = 'start-btn'

    if (hasFinished) {
      startBtnClassName = 'start-btn start-btn-animation'
    }

    return <div className="page-index bg1">
      {/*<img src="https://static.picbling.cn/72b7d07b-4f26-4a9b-9275-d58558c3dc17.jpg" alt="" style={{width: '100%', height: '100%'}}/>*/}
      <div className='video-container'>
        <video
          preload
          h5-video-player-type="h5-page"
          x-webkit-airplay="true"
          x5-playsinline="true"
          webkit-playsinline="true"
          playsinline="true"
          style={(this.isIphoneX() || this.isIphoneXR() || this.isIphoneXSMAX()) ? {height: '100%'} : {width: '100%'}}
          src="https://static.picbling.cn/7918ac39-abaa-4270-aefb-fe65950c9e30.mp4"
          poster="https://static.picbling.cn/7918ac39-abaa-4270-aefb-fe65950c9e30.mp4?vframe/jpg/offset/0"
          id='videoPlayer'
        />
        {
          !hasPlayed && <div onClick={() => this.handleVideoPlay()}>
            <img className='play-mask' src="https://static.picbling.cn/10ec7322-021f-44c1-8a9b-6c57982340f1.png" alt="" />
            <img className='play-text' src="https://static.picbling.cn/a5a0732d-4b4a-4e66-be40-7ef06eebcf53.png" alt=""/>
            <img className='play-btn' src="https://static.picbling.cn/fdf2e66b-28bb-4645-aa8d-011505a14308.gif" alt="" />
          </div>
        }
        {
          (hasPlayed) && <div onClick={this.handleEnterWx} className={startBtnClassName}>
            <img style={{width: '100%'}} src="https://static.picbling.cn/5ae38ad4-3a65-46d3-bd38-7ba78dd15c40.png"
                 alt=""/>
            {/*<div style={{color: 'white', fontSize: '20px'}}>开始</div>*/}
          </div>
        }
      </div>
      {/*<div onClick={this.handleEnter} className="index-button-wrap touch-highlight">*/}
      {/*  /!*<img style={{width: '100%'}} src="https://biruimg.picbling.cn/nestle/dachu/index_enter_button.png" alt=""/>*!/*/}
      {/*  <div style={{color: 'white', fontSize: '20px'}}>开始</div>*/}
      {/*</div>*/}

    </div>
  }
}
