import { POST_CARD_IMAGE_URLS,PRIZE_ITEM_URLS } from './helper'

const imageSource = [
  'https://static.picbling.cn/4eb9377c-66b8-4144-941d-f7c4290326a1.png',
  'https://static.picbling.cn/5c15f2b2-9cee-4e45-8b8e-576417e28a07.png'
]

export function loadImage (url) {
  let image = new Image()
  image.onload = function () {
    console.log('Load URL:',url)
  }
  image.onerror = function () {

  }
  image.src = url
}

export function loadImages () {
  imageSource.forEach(loadImage)
}
