import React from 'react'
import Page from '../../components/Page'
import './PageResult.less'
import { getResultUrl } from '../../utils/store'
import Toast from 'antd-mobile/lib/toast'
import MtaH5 from "mta-h5-analysis"

export default class PagePostDetails extends Page {
  constructor (props) {
    super(props)
    this.state = {
      poster: undefined,
      hasLoaded: false,
      isShareHelpShow: false,
    }
  }

  componentDidMount () {
    this.initShare()

    MtaH5.pgv()
    Toast.loading("生成通缉令...",0)
  }
  handleShare () {
    this.setState({isShareHelpShow: true})
  }

  handleBack () {
    this.locationReplace('/my/post')
  }

  handleShopClick() {
    MtaH5.clickStat("jump_to_shop", {});
    window.location.href = "https://j.youzan.com/b02rhJ"
  }

  render () {
    const {hasLoaded} = this.state

    let shopBtnClassName = 'shop-btn'

    if (hasLoaded) {
      shopBtnClassName = 'shop-btn start-btn-animation'
    }
    const url = getResultUrl()
    let width = '75%'

    if (this.isIphoneX() || this.isIphoneXR() || this.isIphoneXSMAX()) {
      width = '80%'
    }

    if (this.isIphone6() || this.isIphone6P()) {
      width = '70%'
    }

    return <div className="page-post bg2 bg-bottom">
      <div className='post-container' style={{width: width}}>
        {url && <img className="poster-image" onLoad={()=>{Toast.hide();this.setState({hasLoaded: true})}} alt="" src={url}/>}
      </div>
      <div>
        <img className='save-text' src="https://static.picbling.cn/644e14a1-e9c0-4520-a521-82ffb6874d58.png" alt=""/>
        <img className={shopBtnClassName} src="https://static.picbling.cn/b3405026-b13a-4832-b099-ae53a691ed69.png" alt="" onClick={() => this.handleShopClick()}/>
      </div>
    </div>
  }
}
